import Crm from './Crm'
export default class Residency {
  id!: number
  member_number!: Crm
  nationality!: string
  residency_type!: string
  migration_expedient!: string
  passport!: string | null
  date_start_expedient!: Date | null
  date_appointment_lawyers!: Date | null
  date_documentation!: Date | null
  date_appointment_comptroller_services!: Date | null
  final_number_resolution!: number | null
  date_appeal!: Date | null
  date_give_identification!: Date | null
  date_residency_renovation!: Date | null
  date_approbation_procedure!: Date | null
  date_change_status!: Date | null
  date_last_movement!: Date | null
  appeal_reasons!: string | null
  status_type!: string
  inputmemberNumber!: string
}
