var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container is-fluid"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"form"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Member name'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-autocomplete',{attrs:{"placeholder":_vm.$t('Ex:') + ' Juan Pérez',"keep-first":true,"open-on-focus":true,"field":"client_name","data":_vm.filteredDataObj,"clearable":true},on:{"select":function (option) { return (_vm.selected = option); }},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-content"},[_vm._v(" "+_vm._s(props.option.client_name)+" ")])])]}}],null,true),model:{value:(_vm.objResidency.inputmemberNumber),callback:function ($$v) {_vm.$set(_vm.objResidency, "inputmemberNumber", $$v)},expression:"objResidency.inputmemberNumber"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Nationality'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + '  Japonés'},model:{value:(_vm.objResidency.nationality),callback:function ($$v) {_vm.$set(_vm.objResidency, "nationality", $$v)},expression:"objResidency.nationality"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Type of residence'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":_vm.$t('Select value'),"expanded":""},model:{value:(_vm.objResidency.residency_type),callback:function ($$v) {_vm.$set(_vm.objResidency, "residency_type", $$v)},expression:"objResidency.residency_type"}},_vm._l((_vm.listTypeResidency),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.residency_type)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Migration file'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + '  000-000000'},model:{value:(_vm.objResidency.migration_expedient),callback:function ($$v) {_vm.$set(_vm.objResidency, "migration_expedient", $$v)},expression:"objResidency.migration_expedient"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Passport'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 11111811411'},model:{value:(_vm.objResidency.passport),callback:function ($$v) {_vm.$set(_vm.objResidency, "passport", $$v)},expression:"objResidency.passport"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Start of file'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objResidency.date_start_expedient),callback:function ($$v) {_vm.$set(_vm.objResidency, "date_start_expedient", $$v)},expression:"objResidency.date_start_expedient"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Appointment of a lawyer'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objResidency.date_appointment_lawyers),callback:function ($$v) {_vm.$set(_vm.objResidency, "date_appointment_lawyers", $$v)},expression:"objResidency.date_appointment_lawyers"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Appointment control'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objResidency.date_appointment_comptroller_services),callback:function ($$v) {_vm.$set(_vm.objResidency, "date_appointment_comptroller_services", $$v)},expression:"objResidency.date_appointment_comptroller_services"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Final resolution number'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-input',{attrs:{"type":"number","placeholder":_vm.$t('Ex:') + ' 1234'},model:{value:(_vm.objResidency.final_number_resolution),callback:function ($$v) {_vm.$set(_vm.objResidency, "final_number_resolution", $$v)},expression:"objResidency.final_number_resolution"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Date of appeal'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objResidency.date_appeal),callback:function ($$v) {_vm.$set(_vm.objResidency, "date_appeal", $$v)},expression:"objResidency.date_appeal"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Delivery of writ of summons'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objResidency.date_give_identification),callback:function ($$v) {_vm.$set(_vm.objResidency, "date_give_identification", $$v)},expression:"objResidency.date_give_identification"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Renewal of residency'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today","disabled":""},model:{value:(_vm.objResidency.date_residency_renovation),callback:function ($$v) {_vm.$set(_vm.objResidency, "date_residency_renovation", $$v)},expression:"objResidency.date_residency_renovation"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Approval of process'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objResidency.date_approbation_procedure),callback:function ($$v) {_vm.$set(_vm.objResidency, "date_approbation_procedure", $$v)},expression:"objResidency.date_approbation_procedure"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Change of status'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today","disabled":""},model:{value:(_vm.objResidency.date_change_status),callback:function ($$v) {_vm.$set(_vm.objResidency, "date_change_status", $$v)},expression:"objResidency.date_change_status"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Date of documentation'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objResidency.date_documentation),callback:function ($$v) {_vm.$set(_vm.objResidency, "date_documentation", $$v)},expression:"objResidency.date_documentation"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Last movement'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today","disabled":""},model:{value:(_vm.objResidency.date_last_movement),callback:function ($$v) {_vm.$set(_vm.objResidency, "date_last_movement", $$v)},expression:"objResidency.date_last_movement"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Reason for appeal'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-input',{attrs:{"maxlength":"200","type":"textarea"},model:{value:(_vm.objResidency.appeal_reasons),callback:function ($$v) {_vm.$set(_vm.objResidency, "appeal_reasons", $$v)},expression:"objResidency.appeal_reasons"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('State'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.$t('This field is optional')}},[_c('b-select',{attrs:{"placeholder":_vm.$t('Select value'),"expanded":""},model:{value:(_vm.objResidency.status_type),callback:function ($$v) {_vm.$set(_vm.objResidency, "status_type", $$v)},expression:"objResidency.status_type"}},_vm._l((_vm.typeResidencyStatus),function(option){return _c('option',{key:option.id,ref:"",refInFor:true,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.residency_status)+" ")])}),0)],1)]}}],null,true)})],1)]),_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"button btn-save",attrs:{"loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.confirmation)}}},[_vm._v("Guardar")]),_c('b-button',{staticClass:"button btn-cancel",on:{"click":_vm.close}},[_vm._v("Cancelar")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }