import authModule from '@/store/auth'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Residency from '@/Models/Residency'
import http from '@/services/http'
import Crm from '@/Models/Crm'
import TypeResidency from '@/Models/TypeResidency'
import TypeResidencyStatus from '@/Models/TypeResidencyStatus'
import * as Helpers from '@/helpers'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FormResidency extends Vue {
  @Prop({ required: false, default: null })
  objRow!: Residency
  @Prop({ required: false, default: null })
  isAction!: string
  //el mae seleccionado
  objResidency: Residency = new Residency()
  loading = false
  date: any = new Date()
  name = ''
  way!: string //ruta
  isFileEdit = false
  listMemberNumber: Array<Crm> = []
  listTypeResidency: Array<TypeResidency> = []
  typeResidencyStatus: Array<TypeResidencyStatus> = []
  selected!: any
  get is_login(): boolean {
    return authModule.isAuthenticated
  }
  mounted() {
    this.getnumberMember()
    this.getTypeResidency()
    this.getTypeResidencyStatus()
    if (this.objRow && this.isAction == 'edit') {
      if (this.objResidency.member_number == undefined) {
        this.selected = this.objRow.member_number
      }
      this.$set(this, 'objResidency', this.objRow)
      this.$set(
        this.objResidency,
        'inputmemberNumber',
        this.objRow.member_number.client_name,
      )
    }
  }
  /* get filteredDataObj() {
    let filterData: any = []
    filterData = this.listMemberNumber.filter((option) =>
      Object.values(option)
        .map((x) => String(x))[0]
        .includes(String(this.objResidency.member_number)),
    )
    return filterData.map((x: any) => x.member_number)
  } */
  get filteredDataObj() {
    const filterData = this.listMemberNumber.filter(
      (option) =>
        option.client_name
          .toString()
          .toLowerCase()
          .indexOf(this.objResidency.inputmemberNumber) >= 0,
    )
    return filterData
  }
  async confirmation() {
    console.log(this.isAction)
    const residency = this.residencyForm(this.objResidency)
    switch (this.isAction) {
      case 'add':
        this.way = 'arcr_residency'
        await this.createobjResidency(residency)
        break
      case 'edit':
        await this.updateobjResidency(residency)
        break

      default:
        console.log('ups')
        break
    }
  }
  async createobjResidency(objResidency: any) {
    try {
      await http.post(`api/residency/${this.way}/`, objResidency)
      this.$buefy.toast.open({
        message: 'Agregado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      this.loading = false
      this.$buefy.toast.open({
        message: error.response.data,
        type: 'is-danger',
      })
    }
  }
  async updateobjResidency(objResidency: any) {
    try {
      await http.put(
        `api/residency/arcr_residency/${this.objRow.id}/`,
        objResidency,
      )
      this.$buefy.toast.open({
        message: 'Actualizado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      //console.error(error)
      this.loading = false
      switch (error.response.data.member_number[0]) {
        case 'Ya existe Crm Cliente Archivo con este member number.':
          this.$buefy.toast.open({
            message: `Ya exite un cliente con el mismo numero de miembro`,
            type: 'is-danger',
          })
          break
        default:
          this.$buefy.toast.open({
            message: `Ups!, algo salio mal`,
            type: 'is-danger',
          })
          break
      }
      /* this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: "is-danger",
      }) */
    }
  }

  async getnumberMember() {
    this.loading = true
    try {
      const res = await http.get('api/crm/members_list/')
      if (!res.data) throw new Error(res.data)
      this.listMemberNumber = res.data.map((obj: any) => ({
        ...obj,
        /*  reading_date: new Date(obj.date_observation)
           .toISOString()
           .split('T')[0],
         date_observation: new Date(obj.date_observation), */
      }))
    } catch (error) {
      //console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
    this.loading = false
  }
  async getTypeResidency() {
    this.loading = true
    try {
      const res = await http.get(`api/residency/residency_types/`)
      if (!res.data) throw new Error(res.data)
      this.listTypeResidency = res.data.map((obj: any) => ({
        ...obj,
      }))
    } catch (error) {
      console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
    this.loading = false
  }
  async getTypeResidencyStatus() {
    try {
      const res = await http.get(`api/residency/residency_status/`)
      if (!res.data) throw new Error(res.data)
      this.typeResidencyStatus = res.data.map((obj: any) => ({
        ...obj,
      }))
    } catch (error) {
      console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
  residencyForm(objresidency: Residency) {
    const residency = {
      id: objresidency.id,
      member_number_id: this.selected.member_number,
      nationality: objresidency.nationality,
      residency_type_id: objresidency.residency_type,
      migration_expedient: objresidency.migration_expedient,
      passport: objresidency.passport,
      date_start_expedient: Helpers.dateFormat_db(
        objresidency.date_start_expedient,
      ),
      date_appointment_lawyers: Helpers.dateFormat_db(
        objresidency.date_appointment_lawyers,
      ),
      date_documentation: Helpers.dateFormat_db(
        objresidency.date_documentation,
      ),
      date_appointment_comptroller_services: Helpers.dateFormat_db(
        objresidency.date_appointment_comptroller_services,
      ),
      final_number_resolution: objresidency.final_number_resolution,
      date_appeal: Helpers.dateFormat_db(objresidency.date_appeal),
      date_give_identification: Helpers.dateFormat_db(
        objresidency.date_give_identification,
      ),
      date_residency_renovation: Helpers.dateFormat_db(
        objresidency.date_residency_renovation,
      ),
      date_approbation_procedure: Helpers.dateFormat_db(
        objresidency.date_approbation_procedure,
      ),
      date_change_status: Helpers.dateFormat_db(
        objresidency.date_change_status,
      ),
      date_last_movement: objresidency.date_last_movement,
      appeal_reasons: objresidency.appeal_reasons,
      status_type_id: objresidency.status_type,
    }
    return residency
  }
  close() {
    this.objResidency = new Residency()
    this.$emit('cleanForm')
  }
}
